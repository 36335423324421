<template>
  <div class="lg:w-2/6 md:w-1/2 bg-white shadow-lg rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
    <h3 class='mb-6 text-purple-900 h3'>
      Sign up
    </h3>
    <form  @submit.prevent="signUp">
      <!-- Error message -->
      <div
        v-if="errorMsg"
        class=" text-red-500"
      >
        <span class="text-sm">{{ errorMsg }}</span>
      </div>
      <!-- Input -->
      <div class="relative mb-4">
        <input 
          v-model.trim="name"
          type="text"
          class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-lg outline-none  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Display name" />
      </div>
      <div class="relative mb-4">
        <input 
          v-model.trim="email"
          type="email"
          class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-lg outline-none  text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Email" />
      </div>
      <div class="relative mb-4">
        <input 
          v-model.trim="password"
          type="password"
          class="w-full  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  outline-none text-lg text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Password" />
      </div>
      <div class="relative mb-4">
        <input 
          v-model.trim="password2"
          type="password"
          class="w-full  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  outline-none text-lg text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          placeholder="Confirm password" />
      </div>
      <!-- Submit button -->
      <button 
        type="submit"
        class="w-full text-white border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-blue-600"
      >Submit</button>
    </form>
    <div class="text-center text-sm text-grey-dark mt-4">
      By signing up, you agree to the 
      <a class="no-underline border-b border-grey-dark text-grey-dark" href="#">
        Terms of Service
      </a> and 
      <a class="no-underline border-b border-grey-dark text-grey-dark" href="#">
        Privacy Policy
      </a>
    </div>
    <div class="text-grey-dark mt-6">
      Already have an account? 
      <router-link 
        to="/signin"
        class="no-underline border-b border-blue text-blue"
      >Log in</router-link>.
    </div>
    <!-- <button class="text-white  border-0 py-2 px-8 focus:outline-none font-medium  rounded text-xl bg-green-500 " >Create New Account</button> -->
  </div>
</template>

<script>
import firebaseApp from '@/firebase-app'

export default {
  name: 'SignupView',
  data() {
    return {
      name: null,
      email: null,
      password: null,
      password2: null,
      errorMsg: null
    }
  },
  methods: {
    async signUp() {
      this.errorMsg = null
      let result = await firebaseApp.signup(this.email, this.password, this.name)
      if( result.message ) {
        console.log(result.code, result.message)
        this.errorMsg = await firebaseApp.getCustomErrorMessage(result.code)
      } else {
        // console.log('User signed up: ' + result)
        if( await firebaseApp.signout() ) {
          this.$router.push('/signin')
        } 
      }
    }
  }
}
</script>